import { Link, graphql } from 'gatsby';
import React from 'react';
import logogreen from './../images/djar-groen.png';
import Container from '../components/container/container';
import Layout from '../components/layout/layout';
import PageHeader from '../components/paragraphs/page-header/page-header';
import SEO from '../components/seo';
import './cases.scss';

export default function Cases({ location, data }) {
  const langCode =
    location.pathname.split('/').filter(e => e !== '')[0] === 'en'
      ? 'en'
      : 'nl';
  const sortedItems =
    data.allNodeCase.nodes &&
    data.allNodeCase.nodes[0].created > data.allNodeCase.nodes[1].created
      ? data.allNodeCase.nodes
      : data.allNodeCase.nodes.reverse();
  return (
    <Layout location={location}>
      <SEO title="Cases" lang={langCode} />
      <PageHeader
        title={'Cases'}
        align={'Gecentreerd'}
        colors={'Groen'}
        breadcrumbs={[{ url: '/', title: 'Home' }]}
        langCode={langCode}
      ></PageHeader>
      <Container style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
        <div className="block__cases-overview">
          {data.allNodeCase.nodes &&
            data.allNodeCase.nodes.length > 0 &&
            sortedItems.map(item => {
              return (
                <Link to={item.path.alias} class="case__link">
                  <div className="case">
                    {item.relationships.field_media ? (
                      <img
                        src={
                          item.relationships.field_media?.field_media_image
                            .imageDerivatives.links.blog_overview.href
                        }
                        alt=""
                      />
                    ) : (
                      <div className="placeholder">
                        <img src={logogreen} alt="" />
                      </div>
                    )}
                    <p>{item.title}</p>
                  </div>
                </Link>
              );
            })}
        </div>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query CasesOverview {
    allNodeCase {
      nodes {
        id
        created
        path {
          alias
        }
        title
        status
        body {
          format
          processed
        }
        relationships {
          field_media {
            field_media_image {
              imageDerivatives {
                links {
                  blog_overview {
                    href
                  }
                }
              }
            }
          }
        }
        drupal_internal__nid
      }
    }
  }
`;

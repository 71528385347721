import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './page-header.scss';
import { Link } from 'gatsby';
import Container from '../../container/container';
import { parseDrupalURI } from '../../../helpers/helpers';

const PageHeader = ({ title, breadcrumbs, children, cta, colors, align, langCode }) => {
  return (
    <div
      className={[
        'paragraph__page-header',
        align ? align.toLowerCase() : '',
        colors ? colors.toLowerCase() : 'standaard'
      ].join(' ')}
    >
      <Container>
        <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
        {breadcrumbs && breadcrumbs.length > 0 && (
          <ul className="breadcrumbs">
            {breadcrumbs.map((breadcrumb, index) => {
              return (
                <div className="breadcrumb" key={index}>
                  <li>
                    <Link to={parseDrupalURI(breadcrumb.url, langCode)}>
                      {breadcrumb.title}
                    </Link>
                  </li>
                  <li className="arrow">
                    <FontAwesomeIcon icon={['fa', 'long-arrow-alt-right']} />
                  </li>
                </div>
              );
            })}
            <li dangerouslySetInnerHTML={{ __html: title }}></li>
          </ul>
        )}
        {children && (
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: children }}
          ></div>
        )}
        {cta && cta.length > 0 && (
          <div className="cta">
            {cta.map((button, index) => {
              if (parseDrupalURI(button.uri).charAt(0) === '/') {
                return (
                  <div className="cta__item" key={index}>
                    <Link
                      to={parseDrupalURI(button.uri, langCode)}
                      className={[
                        'btn big',
                        index % 2 === 0
                          ? ''
                          : colors === 'Groen'
                          ? 'secondary dark'
                          : 'secondary'
                      ].join(' ')}
                    >
                      {button.title}
                    </Link>
                  </div>
                );
              } else if (parseDrupalURI(button.uri).charAt(0) === '#') {
                return (
                  <div className="cta__item" key={index}>
                    <a
                      href={parseDrupalURI(button.uri, langCode)}
                      className={[
                        'btn big',
                        index % 2 === 0
                          ? ''
                          : colors === 'Groen'
                          ? 'secondary dark'
                          : 'secondary'
                      ].join(' ')}
                    >
                      {button.title}
                    </a>
                  </div>
                );
              } else {
                return (
                  <div className="cta__item" key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={parseDrupalURI(button.uri, langCode)}
                      className={[
                        'btn big',
                        index % 2 === 0
                          ? ''
                          : colors === 'Groen'
                          ? 'secondary dark'
                          : 'secondary'
                      ].join(' ')}
                    >
                      {button.title}
                    </a>
                  </div>
                );
              }
            })}
          </div>
        )}
      </Container>
    </div>
  );
};

export default PageHeader;
